<template>
  <div class="manager-cv-addendum">
    <ContentBox>
      <div class="row">
        <div class="col-16">
          <h4 class="h4 mb-sm-xs">{{ $t(`managerCvAddendum.headline.${position}`) }}</h4>
        </div>
        <div class="col-8 text-right">
          <InputSelect alt="small"
                       v-if="!$matchMedia.sm"
                       class="display-inlineblock  mr-xs"
                       :options="$t('managerCvAddendum.inputs.language.options')"
                       v-model="showLanguage">
          </InputSelect>
          <Button class="-outlined -light pos-relative -no-padding  -size-32 valign-middle" @click.native="openModal">
            <Icon class="-inline -pos-absolute-center">
              <EditSVG />
            </Icon>
          </Button>
        </div>
      </div>
      <ContentBox>
        <div class="row">
          <div class="col-24">
            <div v-if="localeAddendum" class="ProseMirror" v-html="localeAddendum"></div>
            <p v-else class="color-grey-400">{{ $t('managerCvAddendum.empty') }}</p>
          </div>
        </div>
      </ContentBox>
    </ContentBox>
    <Modal :id="`managerCvAddendumEdit_${position}`"
           :title="$t(`managerCvAddendum.headline.${position}`)"
           class="-thin">
      <FormElement>
        <InputSelect base="managerCvAddendum"
                     name="language"
                     class="mb-s"
                     :options="$t('managerCvAddendum.inputs.language.options')"
                     v-model="editLanguage">
        </InputSelect>
        <InputRichText v-model="addendumInput" allow-headlines></InputRichText>
      </FormElement>
      <template slot="footer">
        <div class="text-right">
          <Button class="button -orange -outlined mr-s" @click.native="closeModal">
            {{ $t('managerCvAddendum.buttons.cancel') }}
          </Button>
          <Button class="-orange" @click.native="saveAndExit">
            {{ $t('managerCvAddendum.buttons.save') }}
          </Button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { patchUser } from '@/api/admins.api';
import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import FormElement from '@/components/elements/FormElement.vue';
import InputSelect from '@/components/elements/inputs/InputSelect.vue';
import InputRichText from '@/components/elements/inputs/InputRichText.vue';
import Modal from '@/components/elements/Modal.vue';

import EditSVG from '@/assets/icons/edit.svg';

export default {
  name: 'ManagerCvAddendum',
  props: {
    uuid: String,
    addendum: Object,
    position: {
      type: String,
      required: true,
      validator: (value) => ['before', 'after'].includes(value),
    },
  },
  data() {
    return {
      showLanguage: this.$i18n.locale,
      editLanguage: this.$i18n.locale,
      addendumInput: '',
    };
  },
  computed: {
    localeAddendum() {
      return this.addendum[this.showLanguage];
    },
  },
  mounted() {
    this.addendumInput = this.localeAddendum;
  },
  methods: {
    openModal() {
      this.editLanguage = this.showLanguage;
      this.addendumInput = this.localeAddendum;
      this.$eventBus.$emit(`open-modal-managerCvAddendumEdit_${this.position}`);
    },

    closeModal() {
      this.$eventBus.$emit(`close-modal-managerCvAddendumEdit_${this.position}`);
    },

    saveAndExit() {
      this.patchUser();
    },

    patchUser() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          patchUser(this.uuid, {
            [`cv_${this.position}_${this.editLanguage}`]: (this.addendumInput !== '') ? this.addendumInput : null,
          }, token)
            .then(() => {
              this.closeModal();
              this.$emit('update');
              this.$eventBus.$emit('notificate');
            })
            .catch((error) => {
              this.$eventBus.$emit('notificate', { status: error.response.status, message: error.response.message });
            });
        });
    },
  },
  components: {
    Button,
    Icon,
    ContentBox,
    FormElement,
    InputSelect,
    InputRichText,
    Modal,
    EditSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/manager-cv-addendum.scss"></style>
